<template>
  <router-view />
</template>

<script>
import { mapState, mapMutations } from "vuex";

// import download from "./misc/download";

export default {
  mounted() {
    this.$store.state.purchase.vm = this;

    this.$env.CONSOLE.warn("$keycloak:", this.$keycloak);

    if (this.loggedIn) {
      this.SET_TOKEN(this.$keycloak.token);
    }

    // download("https://cdn.vkk.io/assets/pdf/iys_kullanim_taahhutnamesi.pdf");

    // if (process.env.NODE_ENV === "development") {
    //   window.onbeforeunload = function() {
    //     return "Sayfa yenileme yapmak istediğinizden eminmisiniz. Kaydedilmeyen aşamalardaki veriler kaybedilecektir?";
    //   };
    // }

    // window.addEventListener("keyup", e => {
    //   this.$env.CONSOLE.log("keyup:", String.fromCharCode(e.keyCode));
    //   if (e.key == "Escape") {
    //     this.$env.CONSOLE.log("EMIT close_modal ...");
    //     this.$root.$emit("close_modal");
    //   }
    // });

    window.addEventListener("online", () => {
      this.$env.CONSOLE.log("ONLINE...");
    });

    window.addEventListener("offline", () => {
      this.$env.CONSOLE.log("OFFLINE !!!");
    });

    // let showDuyuru = true;
    // let duyuru_name = "01-12-2020-aralik-duyuru";  // fiyat-duyuru, tarih-duyuru

    // if (window.localStorage) {
    //   if (localStorage.getItem(duyuru_name)) {
    //     showDuyuru = false;
    //   }
    //   localStorage.setItem(duyuru_name, "OK");
    // } else if (window.sessionStorage) {
    //   if (sessionStorage.getItem(duyuru_name)) {
    //     showDuyuru = false;
    //   }
    //   sessionStorage.setItem(duyuru_name, "OK");
    // }

    // if (showDuyuru) {
    //   // this.SET_MODAL("ModalTarih");
    //   // this.SET_MODAL("ModalFiyat");
    // }
  },

  watch: {
    /**
     * Title değişimi burada iptal edildi. Router a bakınız.
     */
    // $route(to, from) {
    //   document.title = to.meta.title ? "İleti Yönetim Sistemi - "  + to.meta.title : "İleti Yönetim Sistemi";
    // },

    application: {
      deep: true,
      handler(n) {
        this.$env.CONSOLE.log(
          "BASVURU VUEX UPDATE:",
          JSON.parse(JSON.stringify(n))
        );
      },
    },

    payment: {
      deep: true,
      handler(n) {
        this.$env.CONSOLE.log(
          "PAYMENT VUEX UPDATE:",
          JSON.parse(JSON.stringify(n))
        );
      },
    },
  },

  computed: {
    ...mapState("applicant", ["application"]),
    ...mapState("purchase", ["payment"]),
  },

  methods: {
    ...mapMutations("app", ["SET_MODAL"]),
    ...mapMutations("applicant", ["SET_TOKEN"]),
  },

  // mounted() { this.$loading.show() }

  //  mounted() {
  //   window.onpopstate = event => {
  //     console.log("BACK BUTTON PRESSED:", this.$route.path)

  //     //Form dan buraya back button ile gelmiştir.
  //     if (this.$route.path == "/hizmet-saglayici/basvuru/yeni-giris") {
  //       this.$router.go(1)
  //     }

  //     // if (this.$route.path == "/login") {
  //     //   this.$router.push("/"); // redirect to home, for example
  //     // }
  //   };
  // }
};
</script>

<style>
@import "./assets/css/reset.css";
@import "./assets/css/iys.css";
.vld-overlay.is-full-page {
  z-index: 99999999999999;
  position: fixed;
}
</style>
