import axios from "axios";




export default (token) => {
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  }
  if (token) {
    headers = {
      ...headers,
      Authorization: `Bearer ${token}`
    }
  }
  return axios.create({
    baseURL: process.env.VUE_APP_BFF_API || "https://public.vkk.io/api/v1",
    headers
  });
};

export function BelgelerEndpoint(token) {
  let headers = {
    Accept: "*/*",
    "Content-Type": "multipart/form-data",
  }
  if (token) {
    headers = {
      ...headers,
      Authorization: `Bearer ${token}`
    }
  }

  return axios.create({
    baseURL: process.env.VUE_APP_BFF_API || "https://public.vkk.io/api/v1",
    withCredentials: false,
    headers
  });
}
